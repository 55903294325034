import React from 'react';

import { Block } from '../index';

import './features-block.scss';

/**
 * Assets for Partie Features.
 */
import PartiesSrc from '../../images/feature-parties.svg';
import VideoAndVoiceSrc from '../../images/feature-video-and-voice.svg';
import BountiesSrc from '../../images/feature-bounties.svg';
import TournamentsSrc from '../../images/feature-tournaments.svg';
import ClubsSrc from '../../images/feature-clubs.svg';
import FavorsSrc from '../../images/feature-favors.svg';

/**
 * Dummy Data.
 */
const FEATURES = [
  {
    title: 'Parties',
    description: 'Browse, Join and Create Parties based on your in-game objectives.',
    imgSrc: PartiesSrc
  },
  {
    title: 'Video and Voice',
    description: 'Enjoy best-in-class Video and Voice Features powered by Zoom.',
    imgSrc: VideoAndVoiceSrc
  },
  {
    title: 'Bounties',
    description: 'Win cash, prizes and eternal bragging rights.',
    imgSrc: BountiesSrc
  },
  {
    title: 'Tournaments',
    description: 'Join challenging community events and go for glory.',
    imgSrc: TournamentsSrc
  },
  {
    title: 'Clubs',
    description: 'Collaborate, share and talk in dedicated spaces. (Coming Soon)',
    imgSrc: ClubsSrc
  },
  {
    title: 'Favors',
    description: 'Get exclusive perks and in-game loot.',
    imgSrc: FavorsSrc
  }
]

const FeaturesBlock = () => {
  return (
  <Block className="features-block">
    <h3 className="partie-block__title">Partie Features</h3>
    <p className="partie-block__description">Connect and play with others through all-in-one Partie Matchmaking, Voice, Video and Text Chat.</p>
    <div className="features-block__content">
      {FEATURES.map((feature) => (
        <div className="features-block__col" key={feature.title}>
          <div className="features-block__feature">
            <img className="features-block__feature-img" src={feature.imgSrc} alt={feature.title} loading="lazy" />
            <div className="features-block__feature-copy">
              <h4 className="features-block__feature-title">{feature.title}</h4>
              <p className="features-block__feature-description">{feature.description}</p>
            </div>
          </div>
        </div>
      ))}  
    </div>
  </Block>
  )
}

export default FeaturesBlock;
